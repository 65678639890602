@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #f0f0f0;
}

.chat-container {
  max-width: 800px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chat-header {
  background-color: #0084ff;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-body {
  padding: 10px;
  height: 75vh; /* Use percentage to make it responsive */
  overflow-y: auto;
}

.chat-message {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 60%;
}

.chat-message-left {
  background-color: #f1f0f0;
}

.chat-message-right {
  background-color: #dcf8c6;
  margin-left: auto;
}

.chat-footer {
  padding: 10px;
  background-color: #f1f0f0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-footer input {
  border: none;
  padding: 10px;
  border-radius: 10px;
  width: calc(100% - 80px); /* Adjust based on button width */
  margin-right: 10px;
}

.chat-footer button {
  background-color: #0084ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}